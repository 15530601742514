<template>
  <div v-if="event">
    <!-- <div class="event-header"> -->
    <h1 class="title">{{ event.title }}</h1>
    <p class="eyebrow">@{{ event.time }} on {{ event.date }}</p>

    <!-- <h5>Organized by {{ event.organizer ? event.organizer.name : '' }}</h5>
      <h5>Category: {{ event.category }}</h5> -->
    <!-- </div> -->

    <!-- <BaseIcon name="map"><h2>Location</h2></BaseIcon> -->

    <!-- <address>{{ event.location }}</address> -->

    <!-- <h2>Event details</h2> -->
    <p>{{ event.description }}</p>

    <!-- <h2>
      Attendees
      <span class="badge -fill-gradient">{{
        event.attendees ? event.attendees.length : 0
      }}</span>
    </h2>
    <ul class="list-group">
      <li
        v-for="(attendee, index) in event.attendees"
        :key="index"
        class="list-item"
      >
        <b>{{ attendee.name }}</b>
      </li>
    </ul> -->
  </div>
</template>
<script>
import { mapState } from 'vuex'
// import EventService from '@/services/EventService.js'
// import { mapState, mapActions } from 'vuex'
export default {
  //   name: 'EventDetails',
  props: ['id'],
  computed: mapState({
    event: (state) => state.event.event,
  }),

  created() {
    this.$store.dispatch('event/getEvent', this.id)
  },
  //   props: ['id'],
  //   created() {
  //     this.fetchEvent(this.id)
  //   },
  //   computed: mapState({
  //     event: (state) => state.event.event,
  //   }),
  //   methods: mapActions('event', ['fetchEvent']), // namespacing used in event
}
</script>

<style scoped>
/* .location {
  margin-bottom: 0;
}
.location > .icon {
  margin-left: 10px;
}
.event-header > .title {
  margin: 0;
}
.list-group {
  margin: 0;
  padding: 0;
  list-style: none;
}
.list-group > .list-item {
  padding: 1em 0;
  border-bottom: solid 1px #e5e5e5;
} */
</style>
