<template>
  <router-link
    class="event-link"
    :to="{ name: 'EventDetails', params: { id: event.id } }"
  >
    <div class="event-card -shadow">
      <span class="eyebrow">@{{ event.time }} on {{ event.date }}</span>
      <h4 class="title">{{ event.title }}</h4>
      <!-- <BaseIcon name="users">{{ event.attendees.length }} attending</BaseIcon> -->
    </div>
  </router-link>
</template>

<script>
export default {
  // name: 'EventCard',
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style scoped>
.event-card {
  padding: 20px;
  width: 250px;
  margin-bottom: 18px;
  transition: all 0.2s linear;
  cursor: pointer;
  border: 1px solid rgb(26, 58, 20);
}
.event-card:hover {
  transform: scale(1.01);
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.2), 0 1px 15px 0 rgba(0, 0, 0, 0.19);
}
.event-card > .title {
  margin: 0;
}
.event-link {
  color: black;
  text-decoration: none;
  font-weight: 100;
}
</style>
